import {
  Button,
  Divider,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import instagram from "assets/social/instagram.png";
import linkedIn from "assets/social/linkedIn.png";
import youtube from "assets/social/youtube.png";
import twitter from "assets/social/x.png";
import facebook from "assets/social/facebook.png";
import google from "assets/social/google.png";
import logo from "assets/ufar-logo.png";
import massage from "assets/icons/massage.png";
import emailFooter from "assets/icons/emailFooter.png";
import phone from "assets/icons/phone.png";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { hexToRgbA } from "../../utils/supportFunctions";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  apiGetContactData,
  apiUpdateNewsLettersList,
} from "services/SettingServices";
import i18n from "utils/i18n";
import { API_PREFIX } from "services/BaseService";
import { toast } from "react-toastify";
import { t } from "i18next";
import { useSelector } from "react-redux";

interface SocialProps {
  link?: string;
  icon: string;
}
interface SocialBlockProps {
  link?: string | any;
  icon: string | any;
  info?: string | any;
}

export interface FooterProps {
  img: string;
  text: string;
  newsletter_text: string;
  email: string;
  location: string;
  phone_number: string | number;
  facebook_link: string;
  google_link: string;
  instagram_link: string;
  linkedin_link: string;
  twitter_link: string;
  youTube_link: string;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.primary.main,
    overflow: "hidden",
    display: "flex",
    padding: "26px 0 10px 0",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      maxHeight: "unset",
    },
  },
  container: {
    width: "82%",
    maxWidth: 1050,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  links: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#C9C9C9",
    fontSize: 18,
    fontWeight: 400,
    padding: "20px 0 15px",
    flexWrap: "wrap",
  },
  linkItem: {
    cursor: "pointer",
    marginTop: 15,
    fontSize: 14,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "flex-start",
    },
  },
  blockSubtitle: {
    fontSize: 14,
    color: "#C9C9C9",
    lineHeight: "27px",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "21px",
      fontSize: 12,
      margin: "15px 0px",
    },
  },
  social: {
    width: 49,
    height: 49,
    minWidth: 49,
    minHeight: 49,
    borderRadius: "50%",
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
      transition: ".2s",
    },
    "&:active": {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },
    [theme.breakpoints.down("xs")]: {
      width: 40,
      height: 40,
      minWidth: 40,
      minHeight: 40,
    },
  },
  block: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  socialBlock: {
    width: "max-content",
    textDecoration: "none",
    display: "flex",
    maxHeight: 49,
    marginRight: 46,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 12,
    },
    [theme.breakpoints.down(1130)]: {
      marginRight: 26,
    },

    [theme.breakpoints.down(1070)]: {
      marginRight: 10,
    },
  },
  socialBlockInner: {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
    marginLeft: 16,
    [theme.breakpoints.down(1130)]: {
      marginLeft: 12,
    },
    [theme.breakpoints.down(1070)]: {
      marginLeft: 8,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 16,
    },
  },
  socialBlockInnerTitle: {
    color: "#C9C9C9",
    fontSize: 12,
    fontWeight: 400,
  },
  socialBlockInnerInfo: {
    color: "#fff",
    fontSize: 16,
    width: "max-content",
    fontWeight: 500,
    textDecoration: "unset",
    [theme.breakpoints.down("xs")]: {
      wordBreak: "break-word",
    },
  },
  socialContainer: {
    display: "flex",
    alignItems: "center",
    width: "80%",
    maxWidth: "440px",
    justifyContent: "space-between",
    [theme.breakpoints.down(1200)]: {
      maxWidth: 550,
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
      width: "80%",
      maxWidth: "440px",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 20,
      width: "100%",
      maxWidth: "440px",
    },
  },

  divider: {
    background: "rgba(255, 255, 255, 0.07)",
    height: "1px",
    width: "100%",
    position: "absolute",
    top: 310,
    left: 0,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      left: 40,
      top: 280,
    },
    [theme.breakpoints.down("xs")]: {
      left: 20,
      top: 300,
    },
    [theme.breakpoints.down(400)]: {
      top: 320,
    },
  },
  submitBtn: {
    borderRadius: 40,
    backgroundColor: theme.palette.primary.main,
    height: 40,
    width: 125,
    fontWeight: 500,
    fontSize: "14px",
    color: "#fff",
    lineHeight: "20px",
    textTransform: "unset",
    "&:hover": {
      backgroundColor: hexToRgbA(theme.palette.primary.main),
    },
  },
  subscribeEmail: {
    height: 50,
    background: "white",
    borderRadius: "40px!important",
    paddingRight: "7px!important",
    paddingLeft: "30px!important",
    "& fieldset": {
      border: "unset",
    },
  },
  gridItem: {
    height: 209,
    borderRight: "1px solid rgba(255, 255, 255, 0.07)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingBottom: 25,
    paddingRight: 55,
    [theme.breakpoints.down("sm")]: {
      height: "unset",
      borderRight: "unset",
    },
  },
  carrersLink: {
    color: "#C9C9C9",
    textDecoration: "unset",
  },

  newsletterGrid: {
    marginTop: 30,
    paddingBottom: 25,
    paddingLeft: 55,
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingTop: 20,
    },
  },
  mobileDivider: {
    width: "100%",
    background: "rgba(255, 255, 255, 0.07)",
  },
  errorMessage: {
    fontSize: 13,
    color: "#EB2D39 !important",
  },
  successMessage: {
    fontSize: 13,
    color: "#4BB543",
  },
  files: {
    color: "#C9C9C9",
    fontSize: 12,
    margin: "0px 8px",
    cursor: "pointer",
    textDecoration: "unset",
  },

  terms: {
    display: "flex",
    justifyContent: "center",
    margin: "20px auto 0px",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      gap: 4,
    },
  },
}));

const Social = ({ link, icon }: SocialProps) => {
  const classes = useStyles();
  return (
    <div
      className={classes.social}
      key={link}
      onClick={() => link && window.open(link, "_blank")}
    >
      <img src={icon} alt="" />
    </div>
  );
};
const SocialBlock = ({ link, icon, info }: SocialBlockProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isXSMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const spliceLength = isXSMobile ? true : isMobile ? false : true;

  return (
    <a
      href={icon === phone ? `tel:${info}` : `mailto:${info}`}
      className={classes.socialBlock}
      key={link}
    >
      <Social icon={icon} link={link} />
      <div className={classes.socialBlockInner}>
        <div className={classes.socialBlockInnerInfo}>
          {spliceLength && info && info.length > 15
            ? info.slice(0, 15) + "..."
            : icon === phone
            ? "+" + info
            : info}
        </div>
      </div>
    </a>
  );
};

const Footer = ({ menuItems, footerData }: any) => {
  const classes = useStyles();
  const sessionState = useSelector(
    //@ts-ignore
    (state) => state.auth.session
  );
  const authState = { isAuthenticated: sessionState.signedIn };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState<any>();
  const [success, setSuccess] = useState("");
  const [contactData, setContactData] = useState<Partial<FooterProps>>({});

  const regEx = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const onSubmit = () => {
    if (email) {
      if (!regEx.test(email)) {
        setErrorMessage(t("errorMessage_wrongFormat") as string);
        return;
      }
      setErrorMessage("");
      try {
        apiUpdateNewsLettersList({ email: email });
      } catch (e: any) {
        toast.error(e.response.data.message);
      }
      setSuccess(t("successMessage_emailSent") as string);
    } else {
      setSuccess("");
      setErrorMessage(t("errorMessage_requiredEmail") as string);
    }
    setEmail("");
    setTimeout(() => setSuccess(""), 5000);
  };

  const handleChange = (evt: any) => {
    setEmail(evt.target.value);
  };

  useEffect(() => {
    apiGetContactData().then((res) => {
      setContactData(res.data.data);
    });
  }, [i18n.language]);

  const footerLeft = {
    logo: logo,
    subTitle:
      "We ara a lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.",
    socials: [
      { icon: phone, info: contactData?.phone_number },
      {
        icon: massage,
        info: contactData?.email,
        link: "#",
      },
    ],
  };

  const socialLinks = [
    { link: contactData?.instagram_link, icon: instagram },
    { link: contactData?.linkedin_link, icon: linkedIn },
    { link: contactData?.youTube_link, icon: youtube },
    { link: contactData?.twitter_link, icon: twitter },
    { link: contactData?.facebook_link, icon: facebook },
    { link: contactData?.google_link, icon: google },
  ];

  const filteredSocialLinks = socialLinks.filter(
    (each: any) => each.link !== null && each.link !== ""
  );

  return (
    <div className={classes.wrapper}>
      <Divider className={classes.divider} />

      <div className={classes.container}>
        <Grid container>
          <Grid item className={classes.gridItem} md={6} xs={12}>
            <img
              onClick={() => navigate("/main")}
              style={{ cursor: "pointer" }}
              src={API_PREFIX + footerData?.img}
              alt="logo"
              width={200}
            />
            <div className={classes.blockSubtitle}>{footerData?.text}</div>
            <div className={classes.block}>
              {footerLeft.socials.map((el: SocialBlockProps, idx: number) => (
                <SocialBlock
                  icon={el?.icon}
                  info={el.info}
                  link={el.link}
                  key={idx}
                />
              ))}
            </div>
          </Grid>
          <Grid item className={classes.newsletterGrid} md={6} xs={12}>
            <div
              style={{
                color: "#fff",
                fontWeight: 700,
                fontSize: "18px",
                marginBottom: 20,
              }}
            >
              {t("newsletter")}
            </div>
            <div className={classes.blockSubtitle} style={{ marginBottom: 20 }}>
              {footerData?.newsletter_text}
            </div>

            <div className={classes.socialContainer}>
              {filteredSocialLinks.map((el: SocialProps, idx: number) => (
                <Social link={el.link} icon={el.icon} key={idx} />
              ))}
            </div>
          </Grid>

          {isMobile && <Divider className={classes.mobileDivider} />}
          <Grid item md={12} className={classes.links}>
            <Grid container spacing={2}>
              {menuItems.map((el: any, idx: any) => (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={
                    el.pageID === 7
                      ? 3
                      : el.pageID === 12 || el.pageID === 22
                      ? 1
                      : 2
                  }
                  className={classes.linkItem}
                  key={idx}
                  onClick={() =>
                    idx !== menuItems.length - 1 && navigate(el.url.slice(5))
                  }
                >
                  {el.pageID !== 22 ? (
                    el.page
                  ) : (
                    <a
                      href={
                        authState.isAuthenticated
                          ? `https://ufar-career.smartsoft.am?token=${sessionState.token}`
                          : "https://ufar-career.smartsoft.am"
                      }
                      target="_blank"
                      className={classes.carrersLink}
                      rel="noreferrer"
                    >
                      {el.page}
                    </a>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
          {isMobile && <Divider className={classes.mobileDivider} />}

          <div className={classes.terms}>
            {" "}
            {footerData.privacy_policy_file && (
              <a
                className={classes.files}
                href={API_PREFIX + footerData.privacy_policy_file}
                target={"_blank"}
                rel="noreferrer"
              >
                {t("text_conditions")}
              </a>
            )}
            {footerData.terms_conditions_file && (
              <a
                className={classes.files}
                href={API_PREFIX + footerData.terms_conditions_file}
                target={"_blank"}
                rel="noreferrer"
              >
                {t("text_terms")}
              </a>
            )}
            {footerData.ethic_rules_file && (
              <a
                className={classes.files}
                href={API_PREFIX + footerData.ethic_rules_file}
                target={"_blank"}
                rel="noreferrer"
              >
                {t("text_ethic_rules")}
              </a>
            )}
            <a
              className={classes.files}
              href={"https://ufar.am/"}
              target={"_blank"}
              rel="noreferrer"
            >
              {t("footer_ufar")}
            </a>
          </div>
          <Grid
            item
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              height: "max-content",
              marginTop: 5,
              alignItems: "center",
            }}
            md={12}
          >
            <span style={{ color: "#C9C9C9", fontSize: 12 }}>
              &copy; {t("all_rights_reserved")}
            </span>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Footer;
