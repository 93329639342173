import { formData } from "utils/formData";
import ApiService from "./ApiService";
import { NODE_ENV, TOKEN_TYPE } from "./BaseService";

export async function apiCreateAnnouncement(data: any) {
  return ApiService.fetchData({
    url: "/api/cdfc/announcements",
    method: "post",
    data: formData(data),
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function apiCreateEducation(data: any) {
  return ApiService.fetchData({
    url: "/api/user/education",
    method: "post",
    data,
  });
}

export async function apiCreateExperience(data: any) {
  return ApiService.fetchData({
    url: "/api/user/experience",
    method: "post",
    data,
  });
}

export async function apiCreateUserArticle(data: any) {
  return ApiService.fetchData({
    url: "/api/user/article",
    method: "post",
    data,
  });
}

export async function apiCreateUserCourse(data: any) {
  return ApiService.fetchData({
    url: "/api/user/course",
    method: "post",
    data,
  });
}

export async function apiCreateUserSkill(data: any) {
  return ApiService.fetchData({
    url: "/api/user/skill",
    method: "post",
    data,
  });
}

export async function apiCreateWarranty(data: any) {
  return ApiService.fetchData({
    url: "/api/user/warranty",
    method: "post",
    data: formData(data),
  });
}

export async function apiGetArticles(data?: any) {
  return ApiService.fetchData({
    url: `/api/user/article`,
    method: "get",
  });
}

export async function apiGetExperience(typeID: number) {
  return ApiService.fetchData({
    url: `/api/user/experience?typeID=${typeID}`,
    method: "get",
  });
}

export async function apiGetCourses(data: any) {
  return ApiService.fetchData({
    url: `/api/user/course`,
    method: "get",
  });
}

export async function apiGetSkills(typeID: number) {
  return ApiService.fetchData({
    url: `/api/user/skill?typeID=${typeID}`,
    method: "get",
  });
}

export async function apiGetWarranty(data: any) {
  return ApiService.fetchData({
    url: `/api/user/warranty`,
    method: "get",
  });
}

export async function apiGetUser(token?: string) {
  return ApiService.fetchData({
    url: "/api/user",
    method: "get",
    headers: {
      Authorization:
        NODE_ENV === "development" ? `${TOKEN_TYPE}${token}` : undefined,
      "Client-Identifier": "ufar-alumni",
    },
  });
}

export async function apiGetALlExperienceData() {
  return ApiService.fetchData({
    url: `/api/user/experience?cv=${true}`,
    method: "get",
  });
}

export async function apiGetAllSkillData() {
  return ApiService.fetchData({
    url: `/api/user/skill?cv=${true}`,
    method: "get",
  });
}

export async function apiUpdateUser(data: any) {
  return ApiService.fetchData({
    url: "/api/user",
    method: "patch",
    data: formData(data),
  });
}

export async function apiUpdateUserAnnouncement(data: any) {
  return ApiService.fetchData({
    url: `/api/cdfc/announcements/${data.id}`,
    method: "patch",
    data: { ...data, id: undefined },
  });
}

export async function apiUpdateEducation(data: any) {
  return ApiService.fetchData({
    url: `/api/user/education/${data.id}`,
    method: "patch",
    data: { ...data, id: undefined },
  });
}

export async function apiUpdateExperience(data: any) {
  return ApiService.fetchData({
    url: `/api/user/experience/${data.id}`,
    method: "patch",
    data: { ...data, id: undefined },
  });
}

export async function apiUpdateSkill(data: any) {
  return ApiService.fetchData({
    url: `/api/user/skill/${data.id}`,
    method: "patch",
    data: { ...data, id: undefined },
  });
}

export async function apiUpdateUserArticle(data: any) {
  return ApiService.fetchData({
    url: `/api/user/article/${data.id}`,
    method: "patch",
    data: { ...data, id: undefined },
  });
}

export async function apiUpdateUserCourse(data: any) {
  return ApiService.fetchData({
    url: `/api/user/course/${data.id}`,
    method: "patch",
    data: { ...data, id: undefined },
  });
}

export async function apiUpdateWarranty(data: any) {
  return ApiService.fetchData({
    url: `/api/user/warranty/${data.id}`,
    method: "patch",
    data: { ...data, id: undefined },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function apiChangePassword(data: any) {
  return ApiService.fetchData({
    url: "/api/user/resetPassword",
    method: "post",
    data,
  });
}

export async function apiGetUserEducation(data?: any) {
  return ApiService.fetchData({
    url: "/api/user/education",
    method: "get",
  });
}

export async function apiGetUserEducationByID(data?: any) {
  return ApiService.fetchData({
    url: `/api/user/education/${data.id}`,
    method: "get",
  });
}

export async function apiGetExperienceByID(params: any) {
  return ApiService.fetchData({
    url: `/api/user/experience/${params.id}`,
    method: "get",
  });
}

export async function apiGetArticleByID(params: any) {
  return ApiService.fetchData({
    url: `/api/user/article/${params.id}`,
    method: "get",
  });
}
export async function apiGetCourseByID(params: any) {
  return ApiService.fetchData({
    url: `/api/user/course/${params.id}`,
    method: "get",
  });
}
export async function apiGetSkillByID(params: any) {
  return ApiService.fetchData({
    url: `/api/user/skill/${params.id}`,
    method: "get",
  });
}

export async function apiGetWarrantyByID(params: any) {
  return ApiService.fetchData({
    url: `/api/user/warranty/${params.id}`,
    method: "get",
  });
}

export async function apiDeleteEducation(data?: any) {
  return ApiService.fetchData({
    url: `/api/user/education/${data.id}`,
    method: "delete",
  });
}

export async function apiDeleteExperience(data?: any) {
  return ApiService.fetchData({
    url: `/api/user/experience/${data.id}`,
    method: "delete",
  });
}

export async function apiDeleteArticle(data?: any) {
  return ApiService.fetchData({
    url: `/api/user/article/${data.id}`,
    method: "delete",
  });
}

export async function apiDeleteCourse(data?: any) {
  return ApiService.fetchData({
    url: `/api/user/course/${data.id}`,
    method: "delete",
  });
}

export async function apiDeleteSkill(data?: any) {
  return ApiService.fetchData({
    url: `/api/user/skill/${data.id}`,
    method: "delete",
  });
}

export async function apiDeleteWarranty(data?: any) {
  return ApiService.fetchData({
    url: `/api/user/warranty/${data.id}`,
    method: "delete",
  });
}
